import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, SkuPriceFormik as SkuPriceFormikType } from './SkuPrices.type';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { InputElement } from '../FormElements';
import Translator from '../../states/global/Translator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { FromUserTimezoneToTimezone, GetDateMask } from '../../helpers/Converters';
import { User } from '../../states/global/User';
import { FullDataUser } from '../User/User.type';
import TextField from '@mui/material/TextField';
import moment from 'moment';

export const SkuPriceFormik: React.FC<SkuPriceFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const fromUserTimezoneToTimezone = useRecoilValue(FromUserTimezoneToTimezone);
    const userState = useRecoilValue<FullDataUser | null>(User);

    const Formschema = Yup.object({
        shippingPriceUsd: Yup.number()
            .typeError(Trans('messages.t.price_must_be_number'))
            .required(Trans('messages.p.this_field_is_required'))
            .moreThan(0, Trans('messages.p.price_must_be_greater_than_0'))
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, submitForm, setFieldValue } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <InputElement
                                    label={Trans('messages.t.price_in_usd')}
                                    name='shippingPriceUsd'
                                    value={values.shippingPriceUsd}
                                    error={errors.shippingPriceUsd}
                                    touched={touched.shippingPriceUsd}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    sm={12}
                                />
                                {values.shippingPriceUsd && props.dataModal?.id ? (
                                    <>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className='textFieldFormik'>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <MobileDateTimePicker
                                                    label={Trans('messages.t.valid_from')}
                                                    disableFuture={false}
                                                    value={values.validFrom}
                                                    maxDate={values.validTo ? moment(values.validTo) : undefined}
                                                    onChange={(newValue) => {
                                                        setFieldValue(
                                                            'validFrom',
                                                            newValue ? fromUserTimezoneToTimezone(newValue) : ''
                                                        );
                                                    }}
                                                    onAccept={() => {
                                                        props.isValidDateRange(values.validFrom, values.validTo);
                                                    }}
                                                    mask={`${GetDateMask ?? '____-__-__ __:__'}`}
                                                    inputFormat={
                                                        userState
                                                            ? // eslint-disable-next-line max-len
                                                              `${userState?.userSetting.dateFormat} ${userState?.userSetting.timeFormat}`
                                                            : 'YYYY-MM-DD HH:mm'
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name='validFrom'
                                                            size='small'
                                                            helperText={
                                                                params.error
                                                                    ? errors.validFrom
                                                                        ? errors.validFrom
                                                                        : errors.validFrom
                                                                    : ''
                                                            }
                                                            error={!!errors.validFrom}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className='textFieldFormik'>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <MobileDateTimePicker
                                                    label={Trans('messages.t.valid_to')}
                                                    disableFuture={false}
                                                    value={values.validTo}
                                                    onChange={(newValue) => {
                                                        setFieldValue(
                                                            'validTo',
                                                            newValue ? fromUserTimezoneToTimezone(newValue) : ''
                                                        );
                                                    }}
                                                    onAccept={() => {
                                                        props.isValidDateRange(values.validFrom, values.validTo);
                                                    }}
                                                    mask={`${GetDateMask ?? '____-__-__ __:__'}`}
                                                    inputFormat={
                                                        userState
                                                            ? // eslint-disable-next-line max-len
                                                              `${userState?.userSetting.dateFormat} ${userState?.userSetting.timeFormat}`
                                                            : 'YYYY-MM-DD HH:mm'
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name='validTo'
                                                            size='small'
                                                            helperText={
                                                                params.error
                                                                    ? errors.validTo
                                                                        ? errors.validTo
                                                                        : errors.validTo
                                                                    : ''
                                                            }
                                                            error={!!errors.validTo}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
