/* eslint-disable max-len */
import { Dialog, DialogContent, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './ShippingOrderDetails.style';
import * as type from './ShippingOrderDetails.type';
import * as ShippingOrderState from '../../../states/component/ShippingOrder';
import Translator from '../../../states/global/Translator';
import { useStyles } from '../../../styled/global.style';
import { Close, ContentCopy } from '@mui/icons-material';
import UiTable from '../../../components/Ui/UiTable/UiTable';
import { Loading } from '../../../helpers/Loading';
import { FromUTCtoUserTimezone } from '../../../helpers/Converters';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
import { CheckboxElement } from '../../FormElements';
import ClickToCopy from '../../../helpers/ClickToCopy/ClickToCopy';
// import module

export const ShippingOrderDetailsContent: React.FC<type.ShippingOrderDetailsViewProps> = (props): JSX.Element => {
    const classes = useStyles();
    const Trans = useRecoilValue(Translator);
    const statusHistorys = useRecoilValue(ShippingOrderState.StatusHistory);
    const fromUTCtoUserTimezone = useRecoilValue(FromUTCtoUserTimezone);

    return (
        <style.ShippingOrderDetailsContent data-testid='ShippingOrderDetailsContent'>
            <Dialog
                fullScreen={getFullScreenModal()}
                fullWidth={true}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
                className='drawer_full_page'
            >
                <style.AppBarContainer style={{ position: 'relative', textAlign: 'center' }}>
                    <style.UiToolbar>
                        <style.TitlePage>
                            {Trans('messages.t.shipping_order_details')} - #{props.shippingOrderId}
                        </style.TitlePage>

                        <style.ContainerBtnBoxItems>
                            <IconButton
                                edge='end'
                                color='inherit'
                                onClick={() => {
                                    props.closeModal();
                                    props.resetListStatus();
                                }}
                                aria-label='close'
                            >
                                <Close />
                            </IconButton>
                        </style.ContainerBtnBoxItems>
                    </style.UiToolbar>
                </style.AppBarContainer>

                <style.UiScrollbarsDetail
                    autoHeight
                    autoHide
                    renderThumbVertical={({ style, ...props }) => (
                        <div {...props} className='scrollYcustom' style={{ ...style }} />
                    )}
                >
                    <DialogContent>
                        <Grid container justify-content='flex-start' direction='row'>
                            {props.loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <Grid item lg={2} md={2} sm={12} xs={12}>
                                        <style.FieldsetLeftContainer>
                                            <legend className='modalLegend'>
                                                {Trans('messages.t.shipping_order_details')}
                                            </legend>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.from')}: </span>
                                                    <ClickToCopy
                                                        text={props.shippingOrderDetails.locationFrom?.name || ''}
                                                    >
                                                        <input
                                                            readOnly
                                                            className='valueShippingorderDetails'
                                                            value={props.shippingOrderDetails.locationFrom?.name}
                                                        ></input>
                                                    </ClickToCopy>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.to')}: </span>
                                                    <ClickToCopy
                                                        text={props.shippingOrderDetails.locationTo?.name || ''}
                                                    >
                                                        <input
                                                            readOnly
                                                            className='valueShippingorderDetails'
                                                            value={props.shippingOrderDetails.locationTo?.name}
                                                        ></input>
                                                    </ClickToCopy>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.order_for_customer')}: </span>
                                                    <ClickToCopy
                                                        text={props.shippingOrderDetails?.itrackCustomerId?.name || ''}
                                                    >
                                                        <input
                                                            readOnly
                                                            className='valueShippingorderDetails'
                                                            value={props.shippingOrderDetails?.itrackCustomerId?.name}
                                                        ></input>
                                                    </ClickToCopy>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.shipped_at')}: </span>
                                                    <input
                                                        readOnly
                                                        className='valueShippingorderDetails'
                                                        value={
                                                            props.shippingOrderDetails.shippedAt
                                                                ? fromUTCtoUserTimezone(
                                                                      props.shippingOrderDetails.shippedAt,
                                                                      false
                                                                  )
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>
                                                {props.shippingOrderDetails.saleOrderId && (
                                                    <Grid
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className={`${classes.textField} containerValueShippingOrder`}
                                                    >
                                                        <span>{Trans('messages.t.invoice_number')}:</span>
                                                        <div className='clickToCopyContainer'>
                                                            <Tooltip
                                                                title={
                                                                    props.shippingOrderDetails.saleOrderId
                                                                        ? props.shippingOrderDetails.saleOrderId
                                                                        : ''
                                                                }
                                                                aria-label='invoice_numberTooltip'
                                                            >
                                                                <input
                                                                    readOnly
                                                                    className='valueShippingorderDetails'
                                                                    value={
                                                                        props.shippingOrderDetails.saleOrderId
                                                                            ? props.shippingOrderDetails.saleOrderId
                                                                            : '---'
                                                                    }
                                                                ></input>
                                                            </Tooltip>
                                                            <ClickToCopy
                                                                text={
                                                                    props.shippingOrderDetails.saleOrderId
                                                                        ? props.shippingOrderDetails.saleOrderId
                                                                        : ''
                                                                }
                                                            >
                                                                <ContentCopy />
                                                            </ClickToCopy>
                                                        </div>
                                                    </Grid>
                                                )}

                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.invoice_incoterms')}:</span>
                                                    <input
                                                        readOnly
                                                        className='valueShippingorderDetails'
                                                        value={
                                                            props.shippingOrderDetails.invoiceIncoerms
                                                                ? props.shippingOrderDetails.invoiceIncoerms
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>

                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.invoice_exw')}:</span>
                                                    <input
                                                        readOnly
                                                        className='valueShippingorderDetails'
                                                        value={
                                                            props.shippingOrderDetails.invoiceExw
                                                                ? props.shippingOrderDetails.invoiceExw
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>

                                                {props.shippingOrderDetails.shipper && (
                                                    <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                        <span>{Trans('messages.t.shipper')}: </span>
                                                        <input
                                                            readOnly
                                                            className='valueShippingorderDetails'
                                                            value={props.shippingOrderDetails.shipper?.name}
                                                        ></input>
                                                    </Grid>
                                                )}
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.shipped_by')}: </span>
                                                    <input
                                                        className='valueShippingorderDetails'
                                                        value={
                                                            props.shippingOrderDetails.shippedBy
                                                                ? [
                                                                      props.shippingOrderDetails.shippedBy.firstname,
                                                                      props.shippingOrderDetails.shippedBy.lastname
                                                                  ].join(' ')
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.tracking_number')}</span>
                                                    <input
                                                        title={props.shippingOrderDetails.trackingId}
                                                        className='valueShippingorderDetails'
                                                        readOnly
                                                        value={
                                                            props.shippingOrderDetails.trackingId
                                                                ? props.shippingOrderDetails.trackingId
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.imported_invoice_from_trent')}</span>
                                                    <input
                                                        title={
                                                            props.shippingOrderDetails
                                                                .originalImportedTrentInvoiceNumber
                                                        }
                                                        className='valueShippingorderDetails'
                                                        readOnly
                                                        value={
                                                            props.shippingOrderDetails
                                                                .originalImportedTrentInvoiceNumber
                                                                ? props.shippingOrderDetails
                                                                      .originalImportedTrentInvoiceNumber
                                                                : '---'
                                                        }
                                                    ></input>
                                                </Grid>
                                                <Grid lg={12} md={12} sm={12} xs={12} className={classes.textField}>
                                                    <span>{Trans('messages.t.sale_order_id')}</span>
                                                    <input
                                                        title={props.shippingOrderDetails.originalImportedTrentInvoiceNumber}
                                                        className='valueShippingorderDetails'
                                                        readOnly
                                                        value={
                                                            props.shippingOrderDetails.originalImportedTrentInvoiceNumber || '---'
                                                        }
                                                    ></input>
                                                </Grid>

                                                <CheckboxElement
                                                    $minWidth='auto'
                                                    label={Trans('messages.t.tracking_enabled')}
                                                    name='trackingEnabled'
                                                    value={props.shippingOrderDetails.trackingEnabled}
                                                    onChange={() => true}
                                                    disabled
                                                    lg={4}
                                                    md={4}
                                                    sm={4}
                                                />
                                            </Grid>
                                        </style.FieldsetLeftContainer>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <style.FieldsetContainer>
                                            <legend className='modalLegend'>{Trans('messages.t.items_to_send')}</legend>
                                            <div
                                                className='bodyRightItems'
                                                style={!props.fullScreenModal ? { display: 'flex' } : {}}
                                            >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <div className='conttable'>
                                                        <UiTable
                                                            data={props.shippingOrderDetails.items}
                                                            loading={false}
                                                            hidePagination={true}
                                                            hideForceRefresh={true}
                                                            noPagination={true}
                                                            columns={props.columns}
                                                            hiddenColumns={[]}
                                                            globalFilter={true}
                                                            subtractSpace={210}
                                                            customButtonAdd={
                                                                <b>
                                                                    <span className='fo-3'>
                                                                        {Trans('messages.t.items_to_send')}
                                                                    </span>
                                                                </b>
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </div>
                                        </style.FieldsetContainer>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <style.FieldsetContainer>
                                            <legend className='modalLegend'>{Trans('messages.t.order_status')}</legend>
                                            <div
                                                className='bodyRightItems'
                                                style={!props.fullScreenModal ? { display: 'flex' } : {}}
                                            >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <div className='form_new_status' style={{ marginBottom: '13px' }}>
                                                        <div
                                                            className='shipperTableLeft_header'
                                                            style={{ display: 'inline-block' }}
                                                        >
                                                            <span className='fo-3'>
                                                                {Trans('messages.t.current_status')}:
                                                            </span>
                                                            &nbsp;
                                                            <b>
                                                                {props.shippingOrderDetails
                                                                    ? props.shippingOrderDetails.shippingOrderStatus
                                                                          .status
                                                                    : ''}
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <UiTable
                                                        data={statusHistorys}
                                                        loading={false}
                                                        hidePagination={true}
                                                        hideForceRefresh={true}
                                                        noPagination={true}
                                                        columns={props.columnsChangeStatus}
                                                        hiddenColumns={[]}
                                                        globalFilter={true}
                                                        customButtonAdd={
                                                            <b>
                                                                <span className='fo-3'>
                                                                    {Trans('messages.t.status_history')}
                                                                </span>
                                                            </b>
                                                        }
                                                    />
                                                </Grid>
                                            </div>
                                        </style.FieldsetContainer>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                </style.UiScrollbarsDetail>
            </Dialog>
            <style.ShippingOrderDetailsbody />
        </style.ShippingOrderDetailsContent>
    );
};
