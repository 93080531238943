import { Backdrop } from '@mui/material';
import styled, { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../../styled/theme.type';

export const TrentShipmentContent = styled.div``;
export const TrentShipmentbody = createGlobalStyle``;

export const BackdropEl = styled(Backdrop)`
    &.MuiBackdrop-root {
        background: ${({ theme }: ThemeType) => (theme.name === 'Dark' ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.5)')};
    }
`;
