import { CloudUpload } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TrentShipment, TrentShipmentProps, ReimportProps } from './TrentShipment.type';
import { TrentShipmentContent } from './TrentShipment.view';
import { ColumnsTable } from '../../types/common';
import ApiTrentShipment from '../../api/ApiTrentShipment';
import * as TrentShipmentState from '../../states/component/TrentShipment';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ModelTrentShipment, TrentShipmentResponse } from '../../models/ApiTrentShipment.type';
import { CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { Errors, Success } from '../Popup/Popup';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { useStyles } from '../CustomerAddress/CustomerAddress.style';
// import module

const api = new ApiTrentShipment();

const TrentShipmentComp: React.FC<TrentShipmentProps> = () => {
    const setdata = useSetRecoilState<ModelTrentShipment[]>(TrentShipmentState.TrentShipments);
    const Trans = useRecoilValue(Translator);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const getDateMask = useRecoilValue(GetDateMask);
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [orderId, setOrderId] = useState<number>();
    const [clicked, setClicked] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const getAllTrentShipment = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator }: TrentShipmentResponse = await api.getAll(page, limit, order, filter);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator }: TrentShipmentResponse = await api.getAll(i, 200, order, filter);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const importOrderWithReassing = async (onClose, props: ReimportProps): Promise<void> => {
        // eslint-disable-next-line react/prop-types
        importOrder(props.trentShipment, props.reassign);

        onClose();
    };

    const importOrder = async (row: TrentShipment, reassign?: boolean): Promise<void> => {
        setProcessing(true);
        if(!clicked){
            setClicked(true);

            const {
                data: { shipping_orders, sensors }
            }: any = await api.create({
                woNumber: row.woNumber,
                reassign
            });

            if (!shipping_orders) {
                throw shipping_orders;
            }

            if (shipping_orders.created?.length) {
                setOpen(true);
                setOrderId(shipping_orders.created[0].id);
                Success({ text: Trans('messages.t.order_imported_successfully') });
            }

            if (shipping_orders.existing.length) {
                Errors({ text: Trans('messages.t.shipping_order_exist') });
            }

            if (shipping_orders.not_imported.length) {
                const message = shipping_orders.not_imported[0].message;

                Errors({ text: Trans('messages.t.error_importing_order') + '. ' + message });
            }

            if (!reassign && sensors?.validation?.length) {
                popUpConfirm(
                    'Do you want to move sensors to this shipment?',
                    sensors.validation.join(', '),
                    importOrderWithReassing,
                    {
                        reassign: true,
                        trentShipment: row
                    }
                );

                return;
            }
        }
        setProcessing(false)
        setClicked(false);
        setRefresh(true);
    };

    useEffect( () => {
        return () => {
            setClicked(false);
        }
    }, []);

    const ActionsButtons = (original: ModelTrentShipment): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => importOrder(original)}>
                    <CloudUpload />
                    <span>{Trans('messages.t.import_trent_order')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: Trans('messages.t.sale_order_id'),
            accessor: 'woNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70
        },
        {
            Header: Trans('messages.t.supplier'),
            accessor: 'supplier',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.customer'),
            accessor: 'customer',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.shipped_at'),
            accessor: 'shippingDate',
            disableGroupBy: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.shippingDate || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo'
        },
        {
            Header: Trans('messages.t.courier'),
            accessor: 'courier',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            disableSortBy: true,
        },
        {
            Header: Trans('messages.t.tracking_number'),
            accessor: 'trackingNo',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.imported'),
            accessor: 'imported',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => 
                row.original?.shippingOrder ? Trans('messages.t.yes') : Trans('messages.t.no'),
            Export: ({ row }: CellProps) => 
                row.original?.shippingOrder ? Trans('messages.t.yes') : Trans('messages.t.no'),
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <TextField
                        className={classes.inputFilterColumn}
                        select
                        variant='outlined'
                        label={column.Header}
                        value={column.filterValue?.value}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            const value = !e.target.value
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: e.target.value
                                  };
                            setFilter(column.id, value);
                        }}
                    >
                        <MenuItem value={`1;${Trans('messages.t.yes')}`}>{Trans('messages.t.yes')}</MenuItem>
                        <MenuItem value={`0;${Trans('messages.t.no')}`}>{Trans('messages.t.no')}</MenuItem>
                    </TextField>
                );
            }
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            width: 80,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            disableExport: true,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                !original.shippingOrder ? <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} /> : null
            )
        }
    ];

    const handleClose = (): void => {
        //
        setOpen(false);
        setOrderId(undefined);
    };

    const handleSubmit = (): void => {
        //
    };

    return (
        <TrentShipmentContent
            data-testid={'TrentShipment-testid'}        
            open={open}
            isLoading={loading}
            columns={columns}
            fetchData={getAllTrentShipment}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            refresh={refresh}
            closeModal={handleClose}
            handleSubmit={handleSubmit}
            orderId={orderId}
            processing={processing}
        />
    );
};

export default TrentShipmentComp;
