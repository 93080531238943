import { MenuItem, Switch, Tooltip, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AutoMode, Delete, Download, Edit, ListAlt, LocalShipping, PanTool, Redo, Replay } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as style from './OrderList.style';
import * as type from './OrderList.type';
import * as view from './OrderList.view';
import ApiShippingOrders from '../../api/ApiShippingOrders';
import { Success } from '../Popup/Popup';
import EditShippingOrder from '../ShippingOrder/EditShippingOrder/EditShippingOrder';
import MarkAsShipped from '../ShippingOrder/MarkAsShipped/MarkAsShipped';
import ShippingOrderFiles from '../ShippingOrder/ShippingOrderFiles/ShippingOrderFiles';
import ShipmentAcceptance from '../ShippingOrder/ShipmentAcceptance/ShipmentAcceptance';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import { ColumnsTable } from '../../types/common';
import Wrapper from '../../helpers/wrapper';
import * as CurrentOrdersState from '../../states/component/CurrentOrders';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ContainerSelectFilter, LabelSwitchFilter, LinkAncla, TextEllipsis } from '../../styled/global.style';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { verifyPermission } from '../../helpers/Auth';
import ButtonDownloadFile from '../ButtonDownloadFile/ButtonDownloadFile';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import { ShippingOrderStatusEnum, ShippingOrderStatusIdsEnum } from './OrderList.type';
import { Model } from '../../models/ApiShippingOrders.type';
import MarkAsLostShipmentButton from '../MarkAsLostShipmentButton/MarkAsLostShipmentButton';
import ResolveDispute from '../ShippingOrder/ResolveDispute/ResolveDispute';
import ApiStockLocation from '../../api/ApiStockLocation';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FullDataUser } from '../User/User.type';
import * as UserState from '../../states/global/User';
import { resendOrderButtonCanBeDisplayed } from '../../helpers';
// import module

const api = new ApiShippingOrders();
const apiStockLocation = new ApiStockLocation();

const OrderList: React.FC<type.OrderListProps> = () => {
    const Trans = useRecoilValue(Translator);
    const getDateMask = useRecoilValue(GetDateMask);
    const classes = style.useStyles();
    const setdata = useSetRecoilState<any>(CurrentOrdersState.OrderList);
    const [loading, setLoading] = useState<boolean>(true);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openMarkAsShipped, setOpenMarkAsShipped] = useState<boolean>(false);
    const [openOpenShippingOrder, setOpenShippingOrder] = useState<boolean>(false);
    const [openSetShipmentStatus, setSetShipmentStatus] = useState<boolean>(false);
    const [shippingOrder, setshippingOrder] = useState<Model | null>(null);
    const [openModalShipmentAcceptence, setOpenModalShipmentAcceptence] = useState<boolean>(false);
    const [opeEditSO, setOpeEditSO] = useState<boolean>(false);
    const stockLocationState = useRecoilValue<any>(StockLocation);
    const stockLocationRegionState = useRecoilValue<any>(StockLocationRegion);
    const [reloadList, setReloadList] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [locationFilter, setLocationFilter] = React.useState<{ locationFilter: string } | Record<string, unknown>>(
        {}
    );
    const showbtnEdit = verifyPermission(['edit_order']);
    const showbtnMarkAsShipped = verifyPermission(['mark_as_shipped_order']);
    const showbtnShippmentAcceptance = verifyPermission(['shippment_acceptance_order']);
    const showbtnReverse = verifyPermission(['reverse_order']);
    const showbtnCancel = verifyPermission(['cancel_order']);
    const user = useRecoilValue<FullDataUser | null>(UserState.User);
    const [isResend, setIsResend] = useState<boolean>(false);

    async function loadMoreStockLocation(search, loadedOptions) {
        const perPage = 10;
        const page = Math.trunc(loadedOptions.length / perPage);

        const { items }: any = await apiStockLocation.getWithPagination(
            page + 1,
            perPage,
            '',
            '',
            search,
            [{ id: 'type', value: 'active' }],
            '?order=name:asc'
        );

        return {
            options: items,
            hasMore: items.length == perPage
        };
    }

    async function getData(page, limit, order, filter, extraFiltering) {
        const params = [extraFiltering.locationFilter.substring(1)];

        const uri = 'stock/shipping-order/all/all?' + params.filter((i) => i).join('&');

        return await api.getAll(`${uri}`, page, limit, order, filter);
    }

    const getAllShippingOrder = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 100, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const detailsShippingOrder = async (row: any) => {
        setshippingOrderId(row.id);
        openModal();
    };

    const handleClose = (): void => {
        setOpen(false);
        setOpeEditSO(false);
        setOpenMarkAsShipped(false);
        setOpenShippingOrder(false);
        setSetShipmentStatus(false);
        setOpenModalShipmentAcceptence(false);
    };

    const openModal = (typeOperation = 'D'): void => {
        switch (typeOperation) {
            case 'D':
                setOpen(true);
                break;
            case 'SA':
                setOpenModalShipmentAcceptence(true);
                break;
            default:
                setOpen(true);
                break;
        }
    };

    const openModalMarkAsShipped = (row: Model): void => {
        setOpenMarkAsShipped(true);
        setshippingOrderId(row.id);
        setshippingOrder(row);
    };

    const openModalSetShipmentStatus = (row: Model): void => {
        setSetShipmentStatus(true);
        setshippingOrderId(row.id);
        setshippingOrder(row);
    };

    const openModalShippingOrderFiles = (row) => {
        setOpenShippingOrder(true);
        setshippingOrderId(row.id);
        setshippingOrder(row);
    };

    const shipmentAcceptanceOpen = async (row: Model) => {
        setshippingOrderId(row.id);
        openModal('SA');
    };

    const deleteRow = async (onClose, row: Model) => {
        try {
            const {
                data: { shippingOrder }
            }: any = await api.patchGeneralFree(`stock/shipping-order/${row.id}/cancel`);

            if (!shippingOrder) {
                throw shippingOrder;
            }

            setRefresh(true);
            onClose();

            Success({ text: `${Trans('messages.t.has_been_canceled')} [ID ${row.id}]` });
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const confirmDeleteRow = (row) =>
        popUpConfirm(
            Trans('messages.t.shipping_order') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );

    const reverseShippingOrder = async (onClose, row: any) => {
        try {
            const {
                data: { shippingOrder }
            }: any = await api.patchGeneralFree(`stock/shipping-order/${row.id}/reverse`);
            if (!shippingOrder) {
                throw shippingOrder;
            }
            onClose();
            Success({ text: `${Trans('messages.t.reversed_shipping_order')} [ID ${row.id}]` });
            setRefresh(true);
        } catch (error) {
            console.warn('Get List Shipping Order ', error);
        }
    };

    const confirmReverse = (row) =>
        popUpConfirm(
            Trans('messages.t.shipping_order') + ` #${row.id}`,
            Trans('messages.t.you_are_sure_to_reverse_shipping_order'),
            reverseShippingOrder,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );

    const editShippingOrder = (row) => {
        setIsResend(false);
        setOpeEditSO(true);
        setshippingOrderId(row.id);
        setshippingOrder(row);
    };

    if (reloadList) {
        setRefresh(true);
        setReloadList(false);
    }

    const shippingOrderItemsCount = (shippingOrderItems) => {
        let ctn = 0;
        for (let i = 0; i < shippingOrderItems.length; i++) {
            if (shippingOrderItems[i].item.boxItemsQuantity) {
                ctn += shippingOrderItems[i].item.boxItemsQuantity;
            } else {
                ctn += shippingOrderItems[i].quantity;
            }
        }
        return ctn;
    };

    const ActionsButtons = (original: Model): JSX.Element => {
        const showbtnMarkAsLost: boolean = original.shippingOrderStatus.status !== ShippingOrderStatusEnum.IN_TRANSIT;

        const showbtnSetShipmentStatus: boolean =
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.NEW &&
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.RETURNED
                ? false
                : true;

        const enableMarkAsShipped: boolean =
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.IN_TRANSIT &&
            original.shippingOrderStatus.id !== ShippingOrderStatusIdsEnum.PARTIALLY_DELIVERED &&
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.DELIVERED
                ? false
                : true;

        const enableShipmentAcceptance: boolean =
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.DELIVERED &&
            original.shippingOrderStatus.id !== ShippingOrderStatusIdsEnum.PARTIALLY_DELIVERED &&
            original.shippingOrderStatus.status === ShippingOrderStatusEnum.IN_TRANSIT
                ? false
                : true;

        const enableCancelShippingOrder: string =
            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.NEW ? 'disabled' : 'danger';
        const enableEditShippingOrderByImported: boolean = original.imported === true;
        const enableEditShippingOrder: boolean =
            enableEditShippingOrderByImported ||
            original.shippingOrderStatus.status === ShippingOrderStatusEnum.NEW ||
            original.shippingOrderStatus.status === ShippingOrderStatusEnum.IN_DISPUTE ||
            user?.stockRole.id === 5; // if SUPER ADMIN

        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => detailsShippingOrder(original)}>
                    <ListAlt />
                    <span>{Trans('messages.t.shipping_order_details')}</span>
                </Typography>

                {showbtnEdit && (
                    <Typography
                        className={`dataTableActionsBtn ${!enableEditShippingOrder && 'disabled'}`}
                        onClick={() => (!enableEditShippingOrder ? '' : editShippingOrder(original))}
                    >
                        <Edit />
                        <span>
                            {Trans('messages.t.edit')}
                            {enableEditShippingOrder}
                        </span>
                    </Typography>
                )}
             
                <Typography
                    className={`dataTableActionsBtn ${!resendOrderButtonCanBeDisplayed(original) && 'disabled'}`}
                    onClick={() => {
                        setIsResend(true);
                        setshippingOrderId(original.id);
                        setOpeEditSO(true);
                    }}
                >
                    <Redo />
                    <span>{Trans('messages.t.resend_order')}</span>
                </Typography>

                {showbtnMarkAsShipped && (
                    <Typography
                        className={`dataTableActionsBtn ${enableMarkAsShipped && 'disabled'}  `}
                        onClick={() => (enableMarkAsShipped ? '' : openModalMarkAsShipped(original))}
                    >
                        <LocalShipping />
                        <span>{Trans('messages.t.mark_as_shipped')}</span>
                    </Typography>
                )}

                {false && (
                    <MarkAsLostShipmentButton
                        shippingOrder={original}
                        showbtnMarkAsLost={showbtnMarkAsLost}
                        setRefresh={setRefresh}
                    />
                )}

                <Typography
                    className={`dataTableActionsBtn ${showbtnSetShipmentStatus && 'disabled'}  `}
                    onClick={() => (showbtnSetShipmentStatus ? '' : openModalSetShipmentStatus(original))}
                >
                    <AutoMode />
                    <span>{Trans('messages.t.set_shipment_status')}</span>
                </Typography>

                {showbtnShippmentAcceptance && (
                    <Typography
                        className={`dataTableActionsBtn ${enableShipmentAcceptance && 'disabled'}  `}
                        onClick={() => (enableShipmentAcceptance ? '' : shipmentAcceptanceOpen(original))}
                    >
                        <PanTool />
                        <span>{Trans('messages.t.shipment_acceptance')}</span>
                    </Typography>
                )}

                {showbtnReverse && (
                    <Typography
                        className={`dataTableActionsBtn ${
                            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.IN_TRANSIT && 'disabled'
                        }  `}
                        onClick={() =>
                            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.IN_TRANSIT
                                ? ''
                                : confirmReverse(original)
                        }
                    >
                        <Replay />
                        <span>{Trans('messages.t.reverse')}</span>
                    </Typography>
                )}

                {showbtnCancel && (
                    <Typography
                        className={`dataTableActionsBtn ${enableCancelShippingOrder}  `}
                        onClick={() =>
                            original.shippingOrderStatus.status !== ShippingOrderStatusEnum.NEW
                                ? ''
                                : confirmDeleteRow(original)
                        }
                    >
                        <Delete />
                        <span>{Trans('messages.t.cancel')}</span>
                    </Typography>
                )}

                <Typography className='dataTableActionsBtn' onClick={() => openModalShippingOrderFiles(original)}>
                    <Download />
                    <span>{Trans('messages.t.show_uploaded_files')}</span>
                </Typography>

                <ButtonDownloadFile
                    defaultFileName='sales-orders.xlsx'
                    api={() => api.getXlsx(`${original.id}/export/xlsx?customer=0`)}
                />

                <ButtonDownloadFile
                    defaultFileName='sales-orders.xlsx'
                    buttonName={Trans('messages.t.download_xls_for_customer')}
                    api={() => api.getXlsx(`${original.id}/export/xlsx?customer=1`)}
                />

                <ButtonDownloadFile
                    defaultFileName={'Invoice ' + original.saleOrderId + '.pdf'}
                    buttonName={Trans('messages.t.download_invoice')}
                    api={() => api.getXlsx(`${original.id}/export/invoice?customer=1`)}
                />
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            width: 60,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        },
        {
            width: 0,
            Header: '',
            isVisible: false,
            disableSortBy: true,
            disableGroupBy: true,
            accessor: 'reshipped',
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <div className='ContainerChecboxFilter'>
                        <LabelSwitchFilter>{Trans('messages.t.order_reshiped')}</LabelSwitchFilter>
                        <Switch
                            checked={column.filterValue?.value === '1'}
                            onChange={(e) => {
                                let value: { name: string; value: string; humanValue: string } | undefined = undefined;
                                if (e.target.checked) {
                                    value = {
                                        name: Trans('messages.t.order_reshiped'),
                                        value: '1',
                                        humanValue: Trans('messages.t.yes')
                                    };
                                }
                                setFilter(column.id, value);
                            }}
                            color='primary'
                            name='_checkboxInactive'
                            inputProps={{ 'aria-label': 'secondary checkbox', color: '#ff1616' }}
                        />
                    </div>
                );
            }
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60,
            Cell: ({ row: { original } }: CellProps<object>) => (
                <Tooltip
                    title={Trans('messages.t.shipping_order_details')}
                    aria-label={Trans('messages.t.shipping_order_details')}
                >
                    <LinkAncla href='javascript:void(0);' onClick={() => detailsShippingOrder(original)}>
                        {original.id}
                    </LinkAncla>
                </Tooltip>
            )
        },
        {
            Header: Trans('messages.t.sale_order_id'),
            accessor: 'originalImportedTrentInvoiceNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) => {
                return original.originalImportedTrentInvoiceNumber || '---';
            }
        },
        {
            Header: Trans('messages.t.invoice_number'),
            accessor: 'saleOrderId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.saleOrderId}> {row.original.saleOrderId} </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.from'),
            accessor: 'locationFromIds',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.locationFrom.name}> {row.original.locationFrom.name} </TextEllipsis>
            ),
            Export: ({ row }: CellProps) => row.original.locationFrom.name,
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                            >
                                {column.Header}
                            </InputLabel>
                            <AsyncPaginate
                                isMulti={true}
                                closeMenuOnSelect={false}
                                isClearable={true}
                                options={[]}
                                classNamePrefix='location-select'
                                value={column.filterValue?.selected}
                                loadOptions={loadMoreStockLocation}
                                onChange={(value) => {
                                    const ids = value ? value.map((v) => v.id) : [];
                                    const humanValues = value ? value.map((v) => v.name) : [];

                                    setFilter(column.id, {
                                        stats: column.Header,
                                        name: column.Header,
                                        humanValue: humanValues,
                                        value: ids,
                                        selected: value
                                    });
                                }}
                                getOptionValue={(option) => option['id']}
                                getOptionLabel={(option) => option['name']}
                                menuPosition='fixed'
                            />
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: Trans('messages.t.to'),
            accessor: 'locationToIds',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.locationTo.name}> {row.original.locationTo.name} </TextEllipsis>
            ),
            Export: ({ row }: CellProps) => row.original.locationTo.name,
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                            >
                                {column.Header}
                            </InputLabel>
                            <AsyncPaginate
                                isMulti={true}
                                closeMenuOnSelect={false}
                                isClearable={true}
                                options={[]}
                                classNamePrefix='location-select'
                                value={column.filterValue?.selected}
                                loadOptions={loadMoreStockLocation}
                                onChange={(value) => {
                                    const ids = value ? value.map((v) => v.id) : [];
                                    const humanValues = value ? value.map((v) => v.name) : [];

                                    setFilter(column.id, {
                                        stats: column.Header,
                                        name: column.Header,
                                        humanValue: humanValues,
                                        value: ids,
                                        selected: value
                                    });
                                }}
                                getOptionValue={(option) => option['id']}
                                getOptionLabel={(option) => option['name']}
                                menuPosition='fixed'
                            />
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: Trans('messages.t.status'),
            accessor: 'status',
            disableGroupBy: true,
            width: 130,
            aggregate: 'count',
            canFilters: true,
            Export: ({ row }: CellProps) =>
                Trans('messages.t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase()),
            Cell: ({ row }: CellProps) =>
                Trans('messages.t.' + row.original.shippingOrderStatus.status.replaceAll(' ', '_').toLowerCase()),
            Filter: ({ column, setFilter }: CellProps) => {
                const listStatuses: { id: number; name: string }[] = [];

                for (const i in ShippingOrderStatusEnum) {
                    listStatuses.push({
                        id: ShippingOrderStatusIdsEnum[i],
                        name: Trans('messages.t.' + ShippingOrderStatusEnum[i].replaceAll(' ', '_').toLowerCase())
                    });
                }

                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                            >
                                {column.Header}
                            </InputLabel>
                            <Select
                                className={classes.inputFilterColumn}
                                multiple
                                value={column.filterValue?.value || []}
                                onChange={(e) => {
                                    const value = !e.target.value
                                        ? undefined
                                        : {
                                              stats: column.Header,
                                              name: column.Header,
                                              humanValue: listStatuses
                                                  .filter((status) => e.target.value.includes(status.id))
                                                  .map((status) => status.name),
                                              value: e.target.value
                                          };

                                    setFilter(column.id, value);
                                }}
                                input={<OutlinedInput label='Tag' />}
                                renderValue={(selected) =>
                                    listStatuses
                                        .filter((status) => selected.includes(status.id))
                                        .map((status) => status.name)
                                        .join(', ')
                                }
                            >
                                {listStatuses.map((status) => (
                                    <MenuItem key={status.id} value={status.id} style={{ padding: '0px' }}>
                                        <Checkbox
                                            checked={
                                                column.filterValue && column.filterValue.value
                                                    ? column.filterValue.value.includes(status.id)
                                                    : false
                                            }
                                        />
                                        <ListItemText primary={status.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: Trans('messages.t.items'),
            accessor: 'shippingOrderItem',
            disableGroupBy: true,
            aggregate: 'count',
            width: 60,
            disableFilters: true,
            disableSortBy: true,
            Export: ({ row }: CellProps) => shippingOrderItemsCount(row.original.shippingOrderItem),
            Cell: ({ row }: CellProps<string>) => shippingOrderItemsCount(row.original.shippingOrderItem)
        },
        {
            Header: Trans('messages.t.order_for_customer'),
            accessor: 'customerName',
            disableGroupBy: true,
            aggregate: 'count',
            width: 200,
            Export: ({ row }: CellProps) => (row.original.itrackCustomerId?.name || ''),
            Cell: ({ row: { original } }: CellProps) =>
                original.itrackCustomerId?.name || '---',
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <ContainerSelectFilter>
                        <FormControl sx={{ m: 0, width: 300 }}>
                            <InputLabel
                                shrink={true}
                                variant='outlined'
                                style={{ backgroundColor: 'white', padding: '0 5px 0 0' }}
                            >
                                {column.Header}
                            </InputLabel>
                            <AsyncPaginate
                                isMulti={true}
                                closeMenuOnSelect={false}
                                isClearable={true}
                                options={[]}
                                classNamePrefix='location-select'
                                value={column.filterValue?.selected}
                                loadOptions={loadMoreStockLocation}
                                onChange={(value) => {
                                    const humanValues = value ? value.map((v) => v.name) : [];

                                    setFilter(column.id, {
                                        stats: column.Header,
                                        name: column.Header,
                                        humanValue: humanValues,
                                        value: humanValues,
                                        selected: value
                                    });
                                }}
                                getOptionValue={(option) => option['id']}
                                getOptionLabel={(option) => option['name']}
                                menuPosition='fixed'
                            />
                        </FormControl>
                    </ContainerSelectFilter>
                );
            }
        },
        {
            Header: Trans('messages.t.shipped_by'),
            accessor: 'shippedBy',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            disableSortBy: true,
            Export: ({ row }: CellProps) =>
                row.original.shippedBy ? row.original.shippedBy.firstname + ' ' + row.original.shippedBy.lastname : '',
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.shippedBy ? (
                    <>
                        <span>{original.shippedBy.firstname}</span> <span>{original.shippedBy.lastname}</span>
                    </>
                ) : (
                    '---'
                )
        },
        {
            Header: Trans('messages.t.tracking_number'),
            accessor: 'trackingId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.trackingId ? (
                    <TextEllipsis title={original.trackingId}> {original.trackingId} </TextEllipsis>
                ) : (
                    '---'
                )
        },
        {
            Header: Trans('messages.t.shipper'),
            accessor: 'shipper.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps<string>) =>
                original.shipper ? (
                    <TextEllipsis title={original.shipper.name}> {original.shipper.name} </TextEllipsis>
                ) : (
                    '---'
                )
        },
        {
            Header: Trans('messages.t.shipped_at'),
            accessor: 'shippedAt',
            canFilters: true,
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: false,
            width: 150,
            Cell: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original?.shippedAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 150,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom',
            isVisible: false
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo',
            isVisible: false
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo'
        },
        {
            Header: Trans('messages.t.status_date'),
            accessor: 'statusAt',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }: CellProps<string>) => {
                const history = original.shippingOrderStatusHistory[original.shippingOrderStatusHistory.length - 1];

                return history?.statusAt ? fromUTCToTimezone(history.statusAt, false) : '---';
            }
        }
    ];

    React.useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setLocationFilter({ locationFilter: `?locationFromName=${stockLocationState.name}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setLocationFilter({ locationFilter: `?fromRegionId=${stockLocationRegionState.id}` });
        } else {
            setLocationFilter({ locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    return (
        <>
            <view.OrderListContent
                data-testid={'OrderList-testid'}
                columns={columns}
                isLoading={loading}
                detailsShippingOrder={detailsShippingOrder}
                total={total}
                fetchData={getAllShippingOrder}
                fetchDataExport={getForExport}
                pageCount={pageCount}
                refresh={refresh}
                extraFiltering={locationFilter}
            />

            {open && <ShippingOrderDetails open={open} closeModal={handleClose} shippingOrderId={shippingOrderId} />}

            {openModalShipmentAcceptence && (
                <ShipmentAcceptance
                    open={openModalShipmentAcceptence}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    setRefresh={setRefresh}
                />
            )}

            {openMarkAsShipped && (
                <MarkAsShipped
                    open={openMarkAsShipped}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    shippingOrder={shippingOrder}
                    setReloadList={setReloadList}
                    setRefresh={setRefresh}
                />
            )}

            {openOpenShippingOrder && (
                <ShippingOrderFiles
                    open={openOpenShippingOrder}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}

            {openSetShipmentStatus && (
                <ResolveDispute
                    open={openSetShipmentStatus}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    shippingOrder={shippingOrder}
                    setRefresh={setRefresh}
                />
            )}

            {opeEditSO && (
                <EditShippingOrder
                    open={opeEditSO}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    shippingOrder={shippingOrder}
                    setRefresh={setRefresh}
                    resendOrder={isResend}
                />
            )}
        </>
    );
};

export default Wrapper(OrderList);
