import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Delete, Edit, ListAlt } from '@mui/icons-material';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TabletContent } from './Tablet.view';
import ApiTablet from '../../api/ApiTablet';
import ApiItems from '../../api/ApiItems';
import ApiSku from '../../api/ApiSku';
import { Success } from '../Popup/Popup';
import { Tablets } from '../../states/component/Tablet';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ColumnsTable, ModalType } from '../../types/common';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import { removeSpaces } from '../../helpers/converter';
import { TextEllipsis } from '../../styled/global.style';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { BoxData } from '../BoxItems/BoxItems.type';
import { ItemsModel, LocationsItems } from '../../models/ApiItems.type';
import { IStockLocation, IStockLocationRegion } from '../../models/ApiStockLocation.type';
import { CrudAction } from '../Box/Box.type';
import { ISignUpForm, TabletProps } from './Tablet.type';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import ImagePopup from '../ImagePopup/ImagePopup';
// import module

const api = new ApiTablet();
const apiItems = new ApiItems();
const apiSku = new ApiSku();

const Tablet: React.FC<TabletProps> = () => {
    const setdata = useSetRecoilState<ItemsModel[]>(Tablets);
    const Trans = useRecoilValue(Translator);
    const [location, setLocation] = useState<LocationsItems | null>(null);
    const [tablet, setTablet] = useState<BoxData | null>(null);
    const [tabletId, setTabletId] = useState<BoxData | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const getDateMask = useRecoilValue(GetDateMask);
    const [openOrderDetails, setOpenOrderDetails] = useState<boolean>(false);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);
    const stockLocationState = useRecoilValue<IStockLocation | null>(StockLocation);
    const stockLocationRegionState = useRecoilValue<IStockLocationRegion | null>(StockLocationRegion);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingForm, setLoadingFrom] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [locationFilter, setLocationFilter] = useState<{ locationFilter: string }>({ locationFilter: '' });
    const [refresh, setRefresh] = useState<boolean>(false);
    const [valuesInitForm, setvaluesInitForm] = useState({
        type: '',
        serialNumber: '',
        locationSelect: {},
        skuSelect: {}
    });
    const [dataModal, setdataModal] = useState<ModalType>({
        isAdd: true,
        title: Trans('messages.t.add_new') + ' ' + Trans('messages.t.tablet'),
        id: null
    });

    const openModal = async (type_form: CrudAction = CrudAction.CREATE, id: number | null = null): Promise<void> => {
        setdataModal({
            isAdd: type_form !== CrudAction.EDIT,
            title:
                type_form !== CrudAction.EDIT
                    ? Trans('messages.t.add_new') + ' ' + Trans('messages.t.tablet')
                    : Trans('messages.t.edit') + ' ' + Trans('messages.t.tablet') + ' - #' + id,
            id: type_form !== CrudAction.EDIT ? null : id
        });
        setTimeout(() => setOpen(true), 100);
        type_form !== CrudAction.EDIT && setLoadingFrom(false);
    };

    async function getData(page, limit, order, filter, extraFiltering) {
        let filterByLocation = '';

        if (extraFiltering.locationFilter) {
            filterByLocation = `&${extraFiltering.locationFilter}`;
        }

        return await apiItems.getAll(
            `?type=tablet&withSerialNumber=0&extend[useData]=1${filterByLocation}`,
            page,
            limit,
            order,
            filter,
            '/v2'
        );
    }

    const getAllTablet = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 200, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const handleClose = (): void => {
        setvaluesInitForm({ type: '', serialNumber: '', locationSelect: {}, skuSelect: {} });
        setLocation(null);
        setOpen(false);
        setLoadingFrom(true);
        setTablet(null);
        setTabletId(null);
        setOpenOrderDetails(false);
    };

    const handleSubmit = async (
        e: ISignUpForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (submitting: boolean) => void
    ) => {
        try {
            e.serialNumber = removeSpaces(e.serialNumber.toString());
            e.quantity = 1;
            e.sku = e.skuSelect.id;
            e.location = e.locationSelect.id;

            const { data }: BoxData = !dataModal.id ? await api.create(e) : await api.patch(dataModal.id, e);

            if (data && !data.item) {
                throw data.item;
            }
            resetForm({});

            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.tablet_created_successfully')} [ID ${data ? data.item.id : '---'}]`
                    : `${Trans('messages.t.tablet_successfully_updated')} [ID ${tabletId ? tabletId.id : ''}]`
            });
            setRefresh(true);

            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editTablet = async (row: BoxData): Promise<void> => {
        setLoadingFrom(true);

        openModal(CrudAction.EDIT, row.id);
        const { data }: BoxData = await api.getByIdGeneral('stock/item', row.id);

        if (data && !data.item) {
            throw data.item;
        }
        if (data) {
            data.item.location && setLocation(data.item.location);

            setTablet(data.item);
            setTabletId(data.item);

            setvaluesInitForm({
                type: '',
                serialNumber: data.item?.serialNumber || '',
                locationSelect: data.item.location ? { id: data.item.location.id, name: data.item.location.name } : {},
                skuSelect: data.item.sku ? { id: data.item.sku.id, name: data.item.sku.name } : {}
            });
        }
        setLoadingFrom(false);
    };

    const deleteRow = async (onClose, row: BoxData): Promise<void> => {
        try {
            const tablet: BoxData = await api.remove(row.id);

            if (!tablet) {
                throw tablet;
            }
            setRefresh(true);
            onClose();
            Success({
                text: `${Trans('messages.t.tablet_successfully_removed')} [ID ${row.id}]`
            });
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const confirmDeleteRow = (row): void =>
        popUpConfirm(
            Trans('messages.t.tablet') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );

    const detailsShippingOrder = async (shippingOrderId): Promise<void> => {
        setshippingOrderId(shippingOrderId);
        setOpenOrderDetails(true);
    };

    const ActionsButtons = (original: BoxData): JSX.Element => {
        let existItems: boolean | null = null;
        let canEditTablet: boolean | null = true;

        if (original.shippingOrderStatus) {
            existItems = original.shippingOrderStatus !== 'Cancel';
        }

        if (original.shippingOrderStatus && original.shippingOrderStatus !== 'Cancel') {
            canEditTablet = false;
        }

        return (
            <>
                {canEditTablet ? (
                    <Typography className='dataTableActionsBtn' onClick={() => editTablet(original)}>
                        <Edit />
                        <span>{Trans('messages.t.edit')}</span>
                    </Typography>
                ) : (
                    ''
                )}

                {original.shippedAt && original.shippingOrderId ? (
                    <Typography
                        className='dataTableActionsBtn'
                        onClick={() => detailsShippingOrder(original.shippingOrderId)}
                    >
                        <ListAlt />
                        <span>{Trans('messages.t.view_order')}</span>
                    </Typography>
                ) : (
                    ''
                )}

                <Typography
                    className={`dataTableActionsBtn ${existItems ? 'disabled' : 'danger'}  `}
                    onClick={() => (existItems ? '' : confirmDeleteRow(original))}
                >
                    <Delete />
                    <span>{Trans('messages.t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: Trans('messages.t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) =>
                row.original.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.sku) : '',
            Cell: ({ row }: CellProps) => (
                <ImagePopup
                    imageUrl={apiSku.getImageUrl(row.original.sku)}
                    thumbnailUrl={apiSku.getImageUrl(row.original.sku)}
                />
            ),
            minWidth: 85,
            maxWidth: 90
        },
        {
            Header: Trans('messages.t.sku'),
            accessor: 'sku.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.sku.name}> {row.original.sku.name} </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.sku'),
            accessor: 'skuCustomerLabel',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Export: ({ row }: CellProps) => row.original.sku.customerLabel,
            Cell: ({ row }: CellProps) => row.original.sku.customerLabel
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.stock_location'),
            accessor: 'location.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.location.name}>
                    {row.original.location ? row.original.location.name : '- - - -'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.shipped_at'),
            accessor: 'shippedAt',
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: false,
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.shippedAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.invoice_number'),
            accessor: 'saleOrderId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            disableSortBy: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.saleOrderId}>
                    {row.original.saleOrderId || '---'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.shipping_price_in_usd'),
            accessor: 'shippingOrderItemPriceUsd',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) => original.shippingOrderItemPriceUsd ?? '---'
        },
        {
            Header: Trans('messages.t.use_customer'),
            accessor: 'useCustomer.name',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original?.useCustomer?.name}>
                    {row.original?.useCustomer?.name || '---'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo'
        }
    ];

    useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setLocationFilter({ locationFilter: `locationId=${stockLocationState.id}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setLocationFilter({ locationFilter: `regionId=${stockLocationRegionState.id}` });
        } else {
            setLocationFilter({ locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    return (
        <>
            <TabletContent
                data-testid={'Tablet-testid'}
                openModal={openModal}
                open={open}
                isLoading={loading}
                closeModal={handleClose}
                handleSubmit={handleSubmit}
                columns={columns}
                valuesInitForm={valuesInitForm}
                dataModal={dataModal}
                fullScreenModal={getFullScreenModal()}
                location={location}
                tablet={tablet}
                loadingForm={loadingForm}
                fetchData={getAllTablet}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                extraFiltering={locationFilter}
            />
            {openOrderDetails && (
                <ShippingOrderDetails
                    open={openOrderDetails}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}
        </>
    );
};

export default Tablet;
