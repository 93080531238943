import { Typography } from '@mui/material';
import { Delete, Edit, ListAlt } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ISensorForm, SensorProps } from './Sensor.type';
import * as view from './Sensor.view';
import ApiItems from '../../api/ApiItems';
import ApiSku from '../../api/ApiSku';
import ApiSensor from '../../api/ApiSensor';
import { Success } from '../Popup/Popup';
import { ColumnsTable, ModalType } from '../../types/common';
import { Sensors } from '../../states/component/Sensor';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import { removeSpaces } from '../../helpers/converter';
import { TextEllipsis } from '../../styled/global.style';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { ModelSku } from '../../models/ApiSku.type';
import { ItemsModel, LocationsItems } from '../../models/ApiItems.type';
import { BoxData } from '../BoxItems/BoxItems.type';
import { IStockLocation, IStockLocationRegion } from '../../models/ApiStockLocation.type';
import { CrudAction } from '../Box/Box.type';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import ImagePopup from '../ImagePopup/ImagePopup';
// import module

const api = new ApiSensor();
const apiItems = new ApiItems();
const apiSku = new ApiSku();

const Sensor: React.FC<SensorProps> = (props): JSX.Element => {
    const setdata = useSetRecoilState<ItemsModel[]>(Sensors);
    const Trans = useRecoilValue(Translator);
    const [sensorId, setSensorId] = useState<BoxData | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const stockLocationState = useRecoilValue<IStockLocation | null>(StockLocation);
    const stockLocationRegionState = useRecoilValue<IStockLocationRegion | null>(StockLocationRegion);
    const [loadingForm, setLoadingFrom] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const getDateMask = useRecoilValue(GetDateMask);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [hexMalformation, setHexMalformation] = useState<boolean>(false);
    const [locationFilter, setLocationFilter] = React.useState<{ locationFilter: string }>({ locationFilter: '' });
    const [valuesInitForm, setvaluesInitForm] = useState<ISensorForm>({
        serialNumber: '',
        printedSensorNumber: '',
        serialNumberHex: '',
        locationSelect: {} as LocationsItems,
        skuSelect: {} as ModelSku,
        version: '',
        location: 0,
        sku: 0
    });
    const [openOrderDetails, setOpenOrderDetails] = useState<boolean>(false);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);
    const [dataModal, setdataModal] = useState<ModalType>({
        isAdd: true,
        title: Trans('messages.t.add_new') + ' ' + Trans('messages.t.sensor'),
        id: null
    });

    async function getData(page, limit, order, filter, extraFiltering) {
        let filterByLocation = '';

        if (extraFiltering.locationFilter) {
            filterByLocation = `&${extraFiltering.locationFilter}`;
        }

        return await apiItems.getAll(
            `?type=sensor&withSerialNumber=0&extend[useData]=1&archived=true&${filterByLocation}`,
            page,
            limit,
            order,
            filter,
            '/v2'
        );
    }

    const getAllSensor = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 200, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const openModal = async (type_form: CrudAction = CrudAction.CREATE, id: number | null = null): Promise<void> => {
        setdataModal({
            isAdd: type_form !== CrudAction.EDIT,
            title:
                type_form !== CrudAction.EDIT
                    ? Trans('messages.t.add_new') + ' ' + Trans('messages.t.sensor')
                    : Trans('messages.t.sensor_update') + ' - #' + id,
            id: type_form !== CrudAction.EDIT ? null : id
        });
        setTimeout(() => setOpen(true), 100);
        type_form !== CrudAction.EDIT && setLoadingFrom(false);
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            serialNumber: '',
            serialNumberHex: '',
            printedSensorNumber: '',
            version: '',
            locationSelect: {} as LocationsItems,
            skuSelect: {} as ModelSku,
            location: 0,
            sku: 0
        });
        setOpen(false);
        setLoadingFrom(true);
        setOpenOrderDetails(false);
        setHexMalformation(false);
    };

    const handleSubmit = async (
        e: ISensorForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (submitting: boolean) => void
    ): Promise<void> => {
        const serialNumberSensor: string = e.printedSensorNumber || e.serialNumber;
        const newValues: ISensorForm = { ...e };
        newValues.quantity = 1;
        newValues.location = e.locationSelect.id;
        newValues.sku = e.skuSelect.id;
        newValues.serialNumber = removeSpaces(serialNumberSensor.toString());
        newValues.version = removeSpaces(`${e.version}` ?? '');

        delete newValues.serialNumberHex;
        delete newValues.printedSensorNumber;

        try {
            const { data }: BoxData = !dataModal.id
                ? await api.createGeneral('stock/item', newValues)
                : await api.patch(dataModal.id, newValues);

            if (data && !data.item) {
                throw data.item;
            }

            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.sensor_created_successfully')} [ID ${data ? data.item.id : '---'}]`
                    : `${Trans('messages.t.sensor_successfully_updated')} [ID ${sensorId ? sensorId.id : '---'}]`
            });

            handleClose();
            resetForm({});
            setRefresh(true);
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editSensor = async (row: BoxData): Promise<void> => {
        setLoadingFrom(true);

        setdataModal({
            title: Trans('messages.t.sensor_update'),
            isAdd: false,
            id: row.id
        });

        openModal(CrudAction.EDIT, row.id);

        const { data }: BoxData = await apiItems.getByIdGeneral('stock/item', row.id);

        if (data && !data.item) {
            throw data.item;
        }

        if (data) {
            setSensorId(data.item);
            const setValues = {
                serialNumber: '',
                serialNumberHex: '',
                printedSensorNumber: data.item.serialNumber ?? '',
                version: data.item.version,
                locationSelect: data.item.location ? data.item.location : ({} as LocationsItems),
                skuSelect: data.item.sku ? data.item.sku : ({} as ModelSku),
                location: 0,
                sku: 0
            };
            serialNumberCalculation(
                setValues,
                undefined,
                data?.item?.serialNumber ?? '',
                data.item.sku ? data.item.sku : undefined
            );
        }
        setLoadingFrom(false);
    };

    const confirmDeleteRow = (row): void =>
        popUpConfirm(
            Trans('messages.t.sensor') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row
        );

    const deleteRow = async (onClose, row: BoxData): Promise<void> => {
        try {
            const item: BoxData = await api.remove(row.id);

            if (!item) {
                throw item;
            }
            setRefresh(true);
            onClose();
            Success({
                text: `${Trans('messages.t.sensor_successfully_removed')} [ID ${row.id}]`
            });
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const detailsShippingOrder = async (order_id): Promise<void> => {
        setshippingOrderId(order_id);
        setOpenOrderDetails(true);
    };

    const serialNumberCalculation = async (
        values: ISensorForm,
        setFieldValue?: (name: string, value: string | ModelSku) => void,
        printedSensorNumber?: string,
        skuPass?: ModelSku
    ) => {
        const sku: ModelSku = skuPass || values.skuSelect;

        if (printedSensorNumber && !values.skuSelect?.skuCode) {
            return false;
        }

        const printedSensorNumberValue = printedSensorNumber || values.printedSensorNumber;
        if (!printedSensorNumberValue) {
            return false;
        }

        let valueHEX = '';
        let valueDecimal: number | string = '';

        if (
            (sku?.skuCode && /v[0-4]/.test(sku?.skuCode.toLocaleLowerCase())) ||
            !/v[5-9]|v\d{2,}/.test(sku?.skuCode.toLocaleLowerCase())
        ) {
            valueHEX = printedSensorNumberValue.replace('T', '1000');
            valueDecimal = parseInt(valueHEX, 16);
        }
        if (sku?.skuCode && /v[5-9]|v\d{2,}/.test(sku?.skuCode.toLocaleLowerCase())) {
            valueHEX = `2000${printedSensorNumberValue}`;
            valueDecimal = parseInt(valueHEX, 16);
        }

        setHexMalformation(`${valueDecimal}` == 'NaN');
        const valueDecimalFinish: string = `${valueDecimal}` != 'NaN' ? `${valueDecimal}` : '';
        setvaluesInitForm({
            ...values,
            serialNumber: valueDecimalFinish,
            serialNumberHex: `${valueDecimal}` != 'NaN' ? valueHEX : ''
        });
        if (setFieldValue) {
            setFieldValue('serialNumber', valueDecimalFinish);
            setFieldValue('serialNumberHex', valueDecimalFinish ? valueHEX : '');
            setFieldValue('printedSensorNumber', printedSensorNumberValue);
            setFieldValue('skuSelect', sku);
        }
        return true;
    };

    const ActionsButtons = (original: BoxData): JSX.Element => {
        let existItems: boolean | null = null;
        if (original.shippingOrderStatus) {
            existItems = original.shippingOrderStatus !== 'Cancel';
        }

        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => editSensor(original)}>
                    <Edit />
                    <span>{Trans('messages.t.edit')}</span>
                </Typography>

                {original.shippedAt && original.shippingOrderId ? (
                    <Typography
                        className='dataTableActionsBtn'
                        onClick={() => detailsShippingOrder(original.shippingOrderId)}
                    >
                        <ListAlt />
                        <span>{Trans('messages.t.view_order')}</span>
                    </Typography>
                ) : (
                    ''
                )}
                <Typography
                    className={`dataTableActionsBtn ${existItems ? 'disabled' : 'danger'}  `}
                    onClick={() => (existItems ? '' : confirmDeleteRow(original))}
                >
                    <Delete />
                    <span>{Trans('messages.t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 50
            },
            {
                Header: Trans('messages.t.image'),
                accessor: 'sku.image',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                exportType: 'image',
                Export: ({ row }: CellProps) =>
                    row.original.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.sku) : '',
                Cell: ({ row }: CellProps) => (
                    <ImagePopup
                        imageUrl={apiSku.getImageUrl(row.original.sku)}
                        thumbnailUrl={apiSku.getImageUrl(row.original.sku)}
                    />
                ),
                minWidth: 85,
                maxWidth: 90
            },
            {
                Header: Trans('messages.t.sku'),
                accessor: 'sku.name',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                Cell: ({ row }: CellProps) => (
                    <TextEllipsis title={row.original.sku.name}> {row.original.sku.name} </TextEllipsis>
                )
            },
            {
                Header: Trans('messages.t.sku'),
                accessor: 'skuCustomerLabel',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Export: ({ row }: CellProps) => row.original.sku.customerLabel,
                Cell: ({ row }: CellProps) => row.original.sku.customerLabel
            },
            {
                Header: Trans('messages.t.serial_number'),
                accessor: 'serialNumber',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true
            },
            {
                Header: Trans('messages.t.version'),
                accessor: 'version',
                disableGroupBy: true,
                aggregate: 'count',
                width: 60,
                canFilters: true,
                Cell: ({ row }: CellProps<string>) => (row.original.version ? row.original.version : '- - - -')
            },
            {
                Header: Trans('messages.t.stock_location'),
                accessor: 'locationName',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                Export: ({ row }: CellProps) => (row.original.location ? row.original.location.name : ''),
                Cell: ({ row }: CellProps) => (
                    <TextEllipsis title={row.original.location.name}>
                        {row.original.location ? row.original.location.name : '- - - -'}
                    </TextEllipsis>
                )
            },
            {
                Header: Trans('messages.t.shipped_at'),
                accessor: 'shippedAt',
                disableGroupBy: true,
                aggregate: 'count',
                disableSortBy: false,
                canFilters: true,
                width: 150,
                Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.shippedAt || '', false),
                Filter: ({ setFilter, state }: CellProps) => {
                    return (
                        <>
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.shipped_at_from')}
                                filterName='shippedAtFrom'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.shipped_at_to')}
                                filterName='shippedAtTo'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                        </>
                    );
                }
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                disableGroupBy: true,
                aggregate: 'count',
                width: 0,
                accessor: 'shippedAtFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                width: 0,
                disableGroupBy: true,
                aggregate: 'count',
                accessor: 'shippedAtTo'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                disableGroupBy: true,
                aggregate: 'count',
                width: 0,
                accessor: 'createdAtFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                width: 0,
                disableGroupBy: true,
                aggregate: 'count',
                accessor: 'createdAtTo'
            },
            {
                Header: Trans('messages.t.created_at'),
                accessor: 'createdAt',
                disableGroupBy: true,
                aggregate: 'count',
                Cell: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original.createdAt || '', false),
                Filter: ({ setFilter, state }: CellProps) => {
                    return (
                        <>
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.created_at_from')}
                                filterName='createdAtFrom'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.created_at_to')}
                                filterName='createdAtTo'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                        </>
                    );
                }
            },
            {
                Header: Trans('messages.t.invoice_number'),
                accessor: 'saleOrderId',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                disableSortBy: true,
                Cell: ({ row }: CellProps) => (
                    <TextEllipsis title={row.original.saleOrderId}>
                        {row.original.saleOrderId || '---'}
                    </TextEllipsis>
                )
            },
            {
                Header: Trans('messages.t.shipping_price_in_usd'),
                accessor: 'shippingOrderItemPriceUsd',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row: { original } }: CellProps) => original.shippingOrderItemPriceUsd ?? '---'
            },
            {
                Header: Trans('messages.t.use_customer'),
                accessor: 'useCustomer.name',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row }: CellProps) => (
                    <TextEllipsis title={row.original?.useCustomer?.name}>
                        {row.original?.useCustomer?.name || '---'}
                    </TextEllipsis>
                )
            },
            {
                Header: Trans('messages.t.use_first_installation'),
                accessor: 'useFirstInstallation',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original.useFirstInstallation || '', false),
                Export: ({ row }: CellProps<string>) =>
                    fromUTCToTimezone(row.original.useFirstInstallation || '', false)
            },
            {
                Header: Trans('messages.t.actions'),
                accessor: 'action',
                disableGroupBy: true,
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                    <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
                )
            }
        ],
        []
    );

    useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setLocationFilter({ locationFilter: `locationId=${stockLocationState.id}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setLocationFilter({ locationFilter: `regionId=${stockLocationRegionState.id}` });
        } else {
            setLocationFilter({ locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    return (
        <>
            <view.SensorContent
                data-testid={'Sensor-testid'}
                openModal={openModal}
                open={open}
                isLoading={loading}
                closeModal={handleClose}
                handleSubmit={handleSubmit}
                sensor={sensorId}
                columns={columns}
                valuesInitForm={valuesInitForm}
                dataModal={dataModal}
                loadingForm={loadingForm}
                {...props}
                fetchData={getAllSensor}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                extraFiltering={locationFilter}
                serialNumberCalculation={serialNumberCalculation}
                hexMalformation={hexMalformation}
            />
            {openOrderDetails && (
                <ShippingOrderDetails
                    open={openOrderDetails}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}
        </>
    );
};

export default Sensor;
