import React, { useState } from 'react';
import HideColumns from './Components/HideColumns';
import FilterColumns from './Components/FilterColumns';
import XlsExport from './Components/XlsExport';
import ChipBar from './Components/ChipBar';
import GlobalFilter from './Components/GlobalFilter';
import FeSidePaginationContent from './Components/FeSidePagination/FeSidePagination';
import * as style from './UiTable.style';
import * as type from './UiTable.type';
import {
    IconButton,
    Menu,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Add, Block, FileDownload, Refresh } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import { getLSUser, setToLSUser } from '../../../helpers/localStorage';
import { vi } from 'date-fns/locale';
import { MenuItemHover } from './UiTable.style';

export const UiTableContent: React.FC<type.UiTableViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const instanceTableState: type.InitialStateType | Record<string, unknown> =
        getLSUser<type.InitialStateType>(`table.${props?.table?.title}Table`) || ({} as Record<string, unknown>);
    const [xslAnchorEl, setXslAnchorEl] = React.useState<null | HTMLElement>(null);
    const openXslDropdown = Boolean(xslAnchorEl);

    const handleChangePage = (_, newPage): void => {
        props.table.gotoPage(newPage);
    };

    const handleClickXsl = (event: React.MouseEvent<HTMLElement>) => {
        setXslAnchorEl(event.currentTarget);
      };
      const handleCloseXsl = () => {
        setXslAnchorEl(null);
      };

    const prefix = props.configPrefix ? '_' + props.configPrefix : '';
    const handleChangeRowsPerPage = (event): void => {
        setToLSUser('tableConfig' + prefix, {
            pageSize: event.target.value
        });
        props.table.setPageSize(+event.target.value);
        setToLSUser(`table.${props.table?.title}Table`, {
            ...instanceTableState,
            pageSize: +event.target.value
        });
    };

    const handleToggleHideColumn = (id, visible): void => {
        const hiddenColumns = props.table.allColumns
            .filter((column) => column.id !== 'selection')
            .filter((column) => column.Header.length > 0 && !column.isVisible)
            .map((column) => column.id);

        if (visible) {
            hiddenColumns.push(id);
        } else {
            hiddenColumns.splice(hiddenColumns.indexOf(id), 1);
        }

        setToLSUser(`table.${props.table?.title}Table`, {
            ...instanceTableState,
            hiddenColumns: hiddenColumns
        });

        props.toggleHideColumn(id, visible);
    };

    return (
        <>
            <style.UiTableContent data-testid='UiTableContent'>
                <style.TableActionsCover className='table-actions-cover'>
                    <style.DivLeft>
                        {props.customButtonAdd && props.customButtonAdd}
                        {props.onAdd && (
                            <props.InstanceSmallIconActionButton
                                instance={props.instance}
                                icon={<Add />}
                                onClick={props.onAdd}
                                label={Trans('messages.t.add')}
                                variant='left'
                            />
                        )}
                        <ChipBar
                            filters={props.table.state.filters}
                            table={props.table}
                            currentFilters={props.currentFilters}
                            setCurrentFilters={props.setCurrentFilters}
                        />
                    </style.DivLeft>
                    <style.DivRight>
                        {props.globalFilter && (
                            <>
                                <GlobalFilter
                                    preGlobalFilteredRows={props.table.preGlobalFilteredRows.length}
                                    globalFilter={props.table.globalFilter}
                                    setGlobalFilter={props.table.setGlobalFilter}
                                    title={Trans('messages.t.search')}
                                />
                            </>
                        )}

                        {props.filterOutsideToolbarElement && props.filterOutsideToolbarElement}

                        {!props.noPagination && (
                            <FilterColumns
                                allColumns={props.table.allColumns}
                                table={props.table}
                                currentFilters={props.currentFilters}
                                setCurrentFilters={props.setCurrentFilters}
                            />
                        )}

                        {!props.hideXlsExport && (
                            <>
                                <IconButton
                                    aria-label='xls-exports'
                                    data-testid='xls-export-dropdwon-btn'
                                    aria-haspopup='true'
                                    onClick={handleClickXsl}
                                >
                                    <FileDownload />
                                </IconButton>
                                <Menu
                                    id='xls-export-menu'
                                    data-testid='xls-export-menu'
                                    anchorEl={xslAnchorEl}
                                    open={openXslDropdown}
                                    onClose={handleCloseXsl}
                                >
                                    <MenuItemHover onClick={handleCloseXsl}>
                                        <XlsExport
                                            tooltip={Trans('messages.t.download_xls')}
                                            offIcon={false}
                                            table={props.table}
                                            fetchDataExport={props.fetchDataExport}
                                            currentFilters={props.currentFilters}
                                            extraFiltering={props.extraFiltering}
                                            textFormDisplay
                                        />
                                    </MenuItemHover>
                                    <MenuItemHover onClick={handleCloseXsl}>
                                        <XlsExport
                                            ignoreTypes={['image']}
                                            offIcon={true}
                                            tooltip={Trans('messages.t.download_xls_without_images')}
                                            table={props.table}
                                            fetchDataExport={props.fetchDataExport}
                                            currentFilters={props.currentFilters}
                                            extraFiltering={props.extraFiltering}
                                            textFormDisplay
                                        />
                                    </MenuItemHover>
                                    {props.hasExportSKU && (
                                        <MenuItemHover onClick={handleCloseXsl}>
                                            <XlsExport
                                                tooltip={Trans('messages.t.download_xls_for_customer')}
                                                offIcon={false}
                                                table={props.table}
                                                fetchDataExport={props.fetchDataExport}
                                                currentFilters={props.currentFilters}
                                                extraFiltering={props.extraFiltering}
                                                textFormDisplay
                                                forCustomer
                                            />
                                        </MenuItemHover>
                                    )}
                                    {props.hasExportSKU && (<MenuItemHover onClick={handleCloseXsl}>
                                             <XlsExport
                                                ignoreTypes={['image']}
                                                offIcon={true}
                                                tooltip={Trans('messages.t.download_xls_without_images_for_customer')}
                                                table={props.table}
                                                fetchDataExport={props.fetchDataExport}
                                                currentFilters={props.currentFilters}
                                                extraFiltering={props.extraFiltering}
                                                textFormDisplay
                                                forCustomer
                                            />
                                        </MenuItemHover>
                                    )}
                                </Menu>
                            </>
                        )}

                        {!props.hideForceRefresh && (
                            <IconButton onClick={() => props.refreshTable()}>
                                <Refresh />
                            </IconButton>
                        )}

                        <HideColumns allColumns={props.table.allColumns} toggleHideColumn={handleToggleHideColumn} />
                        {/* <HideColumns allColumns={props.table.allColumns} toggleHideColumn={props.toggleHideColumn} /> */}
                    </style.DivRight>
                </style.TableActionsCover>
                <TableContainer className='dataTableContainer'>
                    <style.UiTable {...props.getTableProps()} size='small' $minHeight={props.minHeight}>
                        <TableHead>
                            {props.table.headerGroups.map((headerGroup, index) => (
                                <style.CustomHeaderRow {...headerGroup.getHeaderGroupProps()} key={`uiTable_${index}`}>
                                    {headerGroup.headers.map((column, index2) => {
                                        const headerProps = {...column.getHeaderProps()};
                                        if(props.hasExportSKU){
                                            headerProps.style = {...headerProps.style, ...(column?.id?.includes('skuCustomer') ? {display: 'none'} : {})};
                                        }
                                        return (
                                        <style.CustomHeaderCell {...headerProps} key={`header${index2}`}>
                                            {column.canSort && column.id !== 'selection' ? (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                    style={column.getSortByToggleProps().style}
                                                    title={column.getSortByToggleProps().title}
                                                    onClick={(e) => {
                                                        column.getSortByToggleProps().onClick(e);
                                                        setTimeout(() => {
                                                            column.isSortedDesc === undefined
                                                                ? setToLSUser(`table.${props.table?.title}Table`, {
                                                                      ...instanceTableState,
                                                                      sortBy: []
                                                                  })
                                                                : setToLSUser(`table.${props.table?.title}Table`, {
                                                                      ...instanceTableState,
                                                                      sortBy: [
                                                                          {
                                                                              id: column.id,
                                                                              desc: column.isSortedDesc
                                                                          }
                                                                      ]
                                                                  });
                                                        }, 1000);
                                                    }}
                                                >
                                                    <>
                                                        {column.Header}
                                                        {column.canFilter ? '' : ''}
                                                    </>
                                                </TableSortLabel>
                                            ) : (
                                                <>
                                                    {column.Header}
                                                    {column.canFilter ? '' : ''}
                                                </>
                                            )}
                                        </style.CustomHeaderCell>
                                    )})}
                                </style.CustomHeaderRow>
                            ))}
                        </TableHead>
                        <style.UiScrollbars
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                            renderThumbHorizontal={({ style, ...props }) => (
                                <div {...props} className='scrollXcustom' style={{ ...style }} />
                            )}
                            $subtractSpace={props.subtractSpace}
                        >
                            <TableBody {...props.table.getTableBodyProps()}>
                                <>
                                    {props.table.page.map((page, i) => {
                                        props.table.prepareRow(page);
                                        return (
                                            <React.Fragment key={i + 100}>
                                                <style.CustomTableRow {...page.getRowProps()} key={`rowT_${i}`}>
                                                    {page.cells.map((cell, k) => {
                                                        const cellProps = {...cell.getCellProps()};
                                                        if(props.hasExportSKU){
                                                            cellProps.style = {...cellProps.style, ...(cell?.column?.id?.includes('skuCustomer') ? {display: 'none'} : {})};
                                                        }
                                                        return (
                                                            <TableCell {...cellProps} key={`TableCell__${k}`}>
                                                                {cell.render('Cell')}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </style.CustomTableRow>
                                                {page.isExpanded ? (
                                                    <style.containerTableSubRows>
                                                        {props.renderRowSubComponent({ page })}
                                                    </style.containerTableSubRows>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            </TableBody>
                            {props.loading ? (
                                <div className='upload-loader' style={{ minHeight: '180px' }}>
                                    <div
                                        className='p-loading-spinner piano-spinner'
                                        style={{ height: '140px', marginTop: '40px' }}
                                    ></div>
                                </div>
                            ) : null}
                            {!props.loading && props.table.page.length === 0 && (
                                <div style={{ lineHeight: '150px', minHeight: '180px', textAlign: 'center' }}>
                                    <div className='no_data_children'>
                                        <span className='no_data_children_item'>
                                            <Block fontSize='large' />
                                        </span>
                                        <h4 className='no_data_children_item'>{Trans('messages.t.there_no_data')}</h4>
                                    </div>
                                </div>
                            )}
                        </style.UiScrollbars>
                    </style.UiTable>
                </TableContainer>
                <style.UiTable $footer>
                    <TableFooter>
                        <TableRow>
                            {props.hidePagination || (!props.table.data.length && props.table.pageCount < 1) ? null : (
                                <TablePagination
                                    rowsPerPageOptions={props.table.rowsPerPageOptions}
                                    component='div'
                                    count={props.total}
                                    rowsPerPage={props.table.state.pageSize}
                                    page={props.table.state.pageIndex}
                                    labelRowsPerPage={Trans('messages.t.files_per_page')}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelDisplayedRows={props.labelDisplayedRows}
                                />
                            )}
                            {props.hidePagination && <FeSidePaginationContent instance={props.table} />}
                        </TableRow>
                    </TableFooter>
                </style.UiTable>
            </style.UiTableContent>
        </>
    );
};
