import { Backdrop, Box, CircularProgress, Paper, Typography } from '@mui/material';
import React, { } from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './TrentShipment.type';
import * as TrentShipmentState from '../../states/component/TrentShipment';
import Translator from '../../states/global/Translator';
import UiTable from '../Ui/UiTable/UiTable';
import EditShippingOrder from '../ShippingOrder/EditShippingOrder/EditShippingOrder';
import { Loading } from '../../helpers/Loading';
import { BackdropEl } from './TrentShipment.style';
// import module

export const TrentShipmentContent: React.FC<type.TrentShipmentViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='trentShipment'
                    data={useRecoilValue(TrentShipmentState.TrentShipments)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter }) => {
                        props.fetchData(page + 1, limit, order, filter);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'woNumber',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>
                {props.open && props.orderId && <EditShippingOrder
                    open={props.open}
                    closeModal={props.closeModal}
                    shippingOrderId={props.orderId}
                    setRefresh={true}
                />
            }
            <BackdropEl
                open={props.processing}
            >
                <div>
                    <Loading style={{minHeight: '100px'}}/>
                </div>
            </BackdropEl>
        </>
    );
};
