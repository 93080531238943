import PortalApi from '../helpers/api/portalApi';
import * as type from '../models/ApiReporting.type';
import { GenerateUrlProps, GenerateUrlResponse } from '../models/ApiReporting.type';

export default class ApiReporting extends PortalApi<type.Model> {
    route = 'reporting';

    generateEmbeddedUrl = async (data: GenerateUrlProps): Promise<GenerateUrlResponse> => {
        return await this.getTemporal(`/generate-embedded-url/dashboard/${data.dashboardId}`, 'v1/stock');
    };
}
