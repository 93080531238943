import React from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { ThemeType } from '../../../styled/theme.type';
import { Checkbox, Fab, MenuItem, Paper, TableCell, TableRow, Theme } from '@mui/material';
import { FontSize } from '../UiTheme';
import { Scrollbars } from 'react-custom-scrollbars-2';

export const UiTableContent = styled.div`
    .dataTableContainer {
        // display: inline-table !important;
        display: grid;
    }
`;

export const DivRight = styled.div`
    float: right;
`;

export const DivLeft = styled.div`
    float: left;
`;

export const UiTable = styled(Table)<{ $footer?: boolean | null; $minHeight?: string }>`
    &.MuiTable-root {
        width: 99%;
        min-height: ${(props) => (props.$footer ? 'auto' : props.$minHeight || '405px')} !important;
    }
    td {
        font-size: ${FontSize};
        line-height: 21px !important;
        border-bottom: none !important;
        padding: 1px 10px !important;
    }
    th {
        line-height: 1rem !important;
        padding: 6px 10px !important;
    }
`;

export const TableActionsCover = styled.div`
    width: 99%;
    height: 35px;
    padding: 5px 5px;
`;

export const CustomTableRow = styled(TableRow)`
    &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

export const CustomHeaderRow = styled(TableRow)`
    background-color: ${({ theme }: ThemeType) => theme.colors.cardLight} !important;
    border-radius: 10px;
`;

export const CustomHeaderCell = styled(TableCell)`
    font-size: ${FontSize} !important;
    line-height: 21px;
    font-weight: 600 !important;
    border-bottom: none !important;
    &:last-child: {
        border: none;
    }
`;

export const ResizeSeparator = styled.div`
    position: absolute;
    cursor: col-resize;
    z-index: 100;
    opacity: 0;
    height: 50%;
    top: 25%;
    transition: all linear 100ms;
    right: -2px;
    width: 3px;
    &.handleactive: {
        border: none;
    }
`;

export const EmptyTable = styled(TableCell)`
    height: 70px;
    border-bottom: #e5e5e5 solid thin;
    background: #f7f7f7;
    text-align: center !important;
`;

export const containerTableSubRows = styled.div`
    box-shadow: ${({ theme }: ThemeType) =>
        theme.name === 'Dark' ? '#161c24 0px 5px 17px 3px inset' : 'inset 0 3px 20px -9px #737373'};
    padding: 25px;
    background: ${({ theme }: ThemeType) => (theme.name === 'Dark' ? '#2f3740' : '#f1f1f1')};
`;

export const useStyles = makeStyles(() =>
    createStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        leftButtons: {},
        rightButtons: { display: 'flex' },
        leftIcons: {
            '&:first-of-type': {
                marginLeft: -12
            }
        },
        rightIcons: {
            padding: 12,
            marginTop: '-6px',
            width: 48,
            height: 48,
            '&:last-of-type': {
                marginRight: -12
            }
        }
    })
);

const areEqual = (
    prevProps: { checked?: boolean; indeterminate?: boolean },
    nextProps: { checked?: boolean; indeterminate?: boolean }
) => prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate;

export const HeaderCheckbox = React.memo(
    styled(Checkbox)({
        fontSize: FontSize,
        margin: '-8px 0 -8px -15px !important',
        padding: '8px 9px',
        '& svg': {
            width: '24px',
            height: '24px'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }),
    areEqual
);
export const StyledPaper = withStyles(() =>
    createStyles({
        root: {
            padding: '0 !important'
        }
    })
)(Paper);

export const StyledTableCellHeader = withStyles(() =>
    createStyles({
        root: {
            borderBottom: 'none',
            fontWeight: 600
        },
        body: {
            fontSize: 12
        }
    })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        }
    })
)(TableRow);

export const RowCheckbox = React.memo(
    styled(Checkbox)({
        fontSize: FontSize,
        margin: '-9px 0 -8px -15px !important',
        padding: '8px 9px 9px 9px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '& svg': {
            width: 24,
            height: 24
        }
    }),
    areEqual
);

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: 'none'
        },
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        body: {
            fontSize: 11
        }
    })
)(TableCell);

export const UiFab = styled(Fab)`
    height: 30px !important;
    width: 30px !important;
    min-height: 30px !important;
`;

export const UiScrollbars = styled(Scrollbars)<{ $subtractSpace?: number }>`
    height: auto !important;
    max-height: calc(100vh - ${(props) => (props.$subtractSpace ? `${props.$subtractSpace}px` : '225px')}) !important;
    display: grid;

    & > div:first-child {
        max-height: calc(
            100vh - ${(props) => (props.$subtractSpace ? `${props.$subtractSpace - 10}px` : '225px')}
        ) !important;
        display: grid;
    }
`;

export const MenuItemHover = styled(MenuItem)`
    &:hover {
        border-radius: 5px;
    }
`;
